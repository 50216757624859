@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@layer base {
    html {
        font-family: 'Roboto', sans-serif;
    }
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}

.custom-shadow {
    box-shadow: 0px 4px 18px 0px rgb(0, 0, 0, 0.12);
}

.loginbg {
    background-image   : url("./../Images/DashboardLoginBg.png");
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;
}

body {
    height: 100vh;
    width : 100vw;
}

#root {
    height: 100vh;
    width : 100vw;
}